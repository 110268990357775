const BASE_URL = '/create-account';

export const createAccountRoutesConfig = {
  basic: {
    currentUrl: [`${BASE_URL}/basic`],
    nextUrl: [`${BASE_URL}/verification`],
  },
  verification: {
    currentUrl: [`${BASE_URL}/verification`],
    nextUrl: [`${BASE_URL}/pin`],
  },
  pin: {
    currentUrl: [`${BASE_URL}/pin`],
    nextUrl: [`${BASE_URL}/type`],
  },
  type: {
    currentUrl: [`${BASE_URL}/type`],
    nextUrl: '',
  },
};
